import $ from '../../cms/node_modules/jquery';
import { mkToast } from '@shared/js/toasts.js';

import { v1BaseUrl } from '@shared/js/apiV1.js';

export const APP_VERSION = "1.3.0";

export function svInit() {
	console.log("Stage Vault v" + APP_VERSION + " - Created by Devin Baeten");
	
	// API Error Handling
	$(document).ajaxError(function(event, request, settings) {
		var errorMessage = "Unable to connect to the server.";
		if (request.status > 0) {
			errorMessage = `Error ${request.status}: ${request.statusText}`;
		}
		mkToast("error", "danger", "Network Error", errorMessage, 5000);
	});
	
	// iCheck
	iCheck();
}

function iCheck() {
	// Persistent version check
	let currentVersion;
	
	const checkVersion = () => {
		$.ajax({
			url: v1BaseUrl + "app/instance",
			type: 'GET',
			success: (response) => {
				if (currentVersion === undefined) {
					currentVersion = response.version;
				} else if (response.version > currentVersion) {
					currentVersion = response.version;
					location.reload();
				}
			},
			error: (error) => {
				console.log(`Error: ${JSON.stringify(error)}`);
			}
		});
	}
	
	checkVersion(); // Run once immediately
	setInterval(checkVersion, 30000); // Then start interval
}

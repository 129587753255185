import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import { APP_VERSION } from '@shared/js/instance.js';
import { v1BaseUrl } from '@shared/js/apiV1.js';
import { renderLibrary } from '/js/library.js';
import { loader, showLoader, hideLoader } from '/js/navigation.js';
import { mkToast } from '@shared/js/toasts.js';

export var userData = {};

export function authStatus(callback) {
  $.ajax({
	url: v1BaseUrl + "account/session",
	type: "GET",
	xhrFields: {
	  withCredentials: true
	},
	success: function (data) {
	  let status = data.status;
	  callback(data.status);
	},
	error: function (jqXHR, textStatus, errorThrown) {
	  console.error("Error: " + textStatus, errorThrown);
	  callback(true); // Avoid loosing state during tmp outage
	},
  });
}

function showAlert(message, alertType = 'danger') {
  const alert = $(`<div class="alert alert-${alertType} alert-dismissible fade show mt-3" role="alert">
	${message}
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>`);
  $('#authPrompt .alert').remove(); // Remove any existing alert
  $('#authPrompt').prepend(alert); // Prepend the new alert
}

function removeAlert() {
	$('#authPrompt .alert').remove();
}

export function promptSignIn() {
  var appContainer = $('#app');
  appContainer.empty();

  var container = $('<div class="container">');
  var row = $('<div class="row justify-content-center mt-5 ms-2 me-2">');
  var col = $('<div class="col-md-6 p-4 bg-dark rounded-4 shadow" style="max-width: 400px;">');
  
  var logoContainer = $('<div class="d-flex justify-content-center mb-4">');
  
  const imagePath = new URL('@shared/media/StageVault.png', import.meta.url);

  var logo = $(`<img src="${imagePath}" class="w-75"/>`);
  logoContainer.append(logo);

  var nav = $('<ul class="nav nav-tabs mb-3" role="tablist">');
  var loginNavItem = $('<li class="nav-item" role="presentation">');
  var loginNavLink = $('<a class="nav-link active" id="login-tab" data-bs-toggle="tab" href="#login" role="tab">Login</a>');
  var signUpNavItem = $('<li class="nav-item" role="presentation">');
  var signUpNavLink = $('<a class="nav-link" id="sign-up-tab" data-bs-toggle="tab" href="#sign-up" role="tab">Sign Up</a>');

  var tabContent = $('<div class="tab-content">');
  var alertContainer = $('<div id="authPrompt">');
  var loginTab = $('<div class="tab-pane fade show active" id="login" role="tabpanel">');
  var signUpTab = $('<div class="tab-pane fade" id="sign-up" role="tabpanel">');

  // Login form
  var loginForm = $('<form class="mb-0"></form>');
  var loginEmailInput = $('<input type="text" class="form-control mb-2" autocomplete="email" placeholder="Email" required>');
  var loginPasswordInput = $('<input type="password" class="form-control mb-3" autocomplete="current-password" placeholder="Password" required>');
  var loginButton = $('<button type="submit" class="btn btn-primary w-100 ">Log In</button>');
  var forgotPasswordLink = $('<a href="#" class="mt-3 d-block text-light w-100 text-center">Forgot Password?</a>');

  loginForm.append(loginEmailInput, loginPasswordInput, loginButton, forgotPasswordLink);
  
  // Sign up form
  var signUpForm = $('<form class="mb-0"></form>');
  var signUpFirstNameInput = $('<input type="text" class="form-control mb-2" autocomplete="name given-name" placeholder="First Name" required>');
  var signUpLastNameInput = $('<input type="text" class="form-control mb-2" autocomplete="name family-name" placeholder="Last Name" required>');
  var signUpEmailInput = $('<input type="text" class="form-control mb-2" autocomplete="email" placeholder="Email" required>');
  var signUpPasswordInput = $('<input type="password" class="form-control mb-3" autocomplete="new-password" placeholder="Password" required>');
  // Terms agreement checkbox
  var signUpTermsAgreement = $(`
	<div class="form-check mb-3">
	  <input type="checkbox" class="form-check-input" id="termsAgreement" required>
	  <label class="form-check-label" for="termsAgreement">
		I have read and agree to the <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/legal/terms-of-use" target="_blank">Terms of Use</a>.
	  </label>
	</div>
  `);
  var signUpButton = $('<button type="submit" class="btn btn-primary w-100">Sign Up</button>');
  signUpForm.append(signUpFirstNameInput, signUpLastNameInput, signUpEmailInput, signUpPasswordInput, signUpTermsAgreement, signUpButton);
  
  let clientInfo = $(`<span class="mt-4 text-center">Created by Devin Baeten<br/>Version ${APP_VERSION}</span>`);
  
  let legal = $(`<span class="mt-4 text-center"><a class="text-light" target="blank" href="https://help.stagevault.com">Help</a> &middot; <a class="text-light" target="blank" href="/legal/privacy-policy">Privacy Policy</a> &middot; <a class="text-light" target="blank" href="/legal/terms-of-use">Terms of Use</a></span>`);

  // Assemble the page
  loginNavItem.append(loginNavLink);
  signUpNavItem.append(signUpNavLink);
  nav.append(loginNavItem, signUpNavItem);
  loginTab.append(loginForm);
  signUpTab.append(signUpForm);
  tabContent.append(alertContainer, loginTab, signUpTab);
  col.append(logoContainer, nav, tabContent);
  row.append(col, legal, clientInfo);
  container.append(row);
  appContainer.append(container);
  
  // Enable or disable the submit button based on whether all fields are filled
	function updateLoginButtonState() {
  		loginButton.prop('disabled', !loginEmailInput.val() || !loginPasswordInput.val());
	}
	
	function updateSignUpButtonState() {
	  const allFieldsFilled = signUpFirstNameInput.val() && signUpLastNameInput.val() && signUpEmailInput.val() && signUpPasswordInput.val();
	  const termsAgreed = $('#termsAgreement').is(':checked');
	
	  // Enable the button only if all fields are filled and terms are agreed to
	  signUpButton.prop('disabled', !(allFieldsFilled && termsAgreed));
	}
	
	loginEmailInput.on('input', updateLoginButtonState);
	loginPasswordInput.on('input', updateLoginButtonState);
	signUpFirstNameInput.on('input', updateSignUpButtonState);
	signUpLastNameInput.on('input', updateSignUpButtonState);
	signUpPasswordInput.on('input', updateSignUpButtonState);
	$('#termsAgreement').on('change', updateSignUpButtonState);
	
	// Initialize the button states
	updateLoginButtonState();
	updateSignUpButtonState();

  // Email validation for the Sign Up form
  
  function isStudentEmail(email) {
	  return email.includes('student.brillion.k12.wi.us');
  }
  
  signUpEmailInput.on('input', function () {
  	updateSignUpButtonState();
	var email = $(this).val();
	if (isStudentEmail(email)) {
		$(this).addClass('is-invalid');
		showAlert("You cannot use a student email address. Those email accounts will be terminated after graduation. Please use a personal email address.", "danger");
	} else {
		$(this).removeClass('is-invalid');
	}
  });
  
  function isFormValid(form) {
	  return form.find('input').toArray().every(input => $(input).val());
	}
  
	function handleSubmit(form, handler) {
	  if (isFormValid(form)) {
		handler();
	  } else {
		showAlert('Please fill out all fields.', 'danger');
	  }
	}
  
	loginForm.on('submit', function (e) {
	  e.preventDefault(); // Prevent the default form submission behavior
	  handleSubmit($(this), () => loginUser(loginEmailInput.val(), loginPasswordInput.val()));
	});
  
	// ...
  
	signUpForm.on('submit', function (e) {
	  e.preventDefault(); // Prevent the default form submission behavior
	  handleSubmit($(this), () =>
		signUpUser(
		  signUpFirstNameInput.val(),
		  signUpLastNameInput.val(),
		  signUpEmailInput.val(),
		  signUpPasswordInput.val(),
		  $("#termsAgreement").is(':checked')
		)
	  );
	});
	
	function createPasswordResetModal() {
	  return $(`
		<div class="modal fade" id="passwordResetModal" tabindex="-1" aria-labelledby="passwordResetModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-header">
				<h5 class="modal-title" id="passwordResetModalLabel">Reset Password</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			  </div>
			  <div class="modal-body">
				<form id="passwordResetForm">
				  <div class="mb-3">
					<label for="emailAddress" class="form-label">Email Address</label>
					<input type="email" class="form-control" id="emailAddress" required>
				  </div>
				</form>
			  </div>
			  <div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				<button type="button" class="btn btn-primary" id="resetPasswordSubmitBtn">Reset Password</button>
			  </div>
			</div>
		  </div>
		</div>
	  `);
	}
	
	function promptPasswordReset() {
	  const passwordResetModal = createPasswordResetModal();
	  $("body").append(passwordResetModal);
	
	  const modalElement = document.getElementById("passwordResetModal");
	  const passwordResetModalInstance = new bootstrap.Modal(modalElement);
	  passwordResetModalInstance.show();
	
	  // Remove the modal from the DOM when it's closed
	  $(modalElement).on("hidden.bs.modal", function () {
		$(this).remove();
	  });
	}
	
	$(document).off("click", "#resetPasswordSubmitBtn").on("click", "#resetPasswordSubmitBtn", function () {
	  const emailAddress = $("#emailAddress").val();
	
	  // Validate the email address
	  if (!emailAddress) {
		alert("Please enter your email address.");
		return;
	  }
	
	  // Call your PHP script to handle the password reset request
	  $.ajax({
		url: v1BaseUrl + "/auth/reset",
		method: "POST",
		xhrFields: {
		  withCredentials: true
		},
		data: {
		  email: emailAddress,
		},
		success: function (response) {
		  if (response.success) {
			showAlert("Check your email inbox for a password reset link.");
		  } else {
			if (response.error !== null) {
				showAlert('Password reset error: ' + response.error);
			} else {
				showAlert("An error occured while trying to request a password reset email.");
			}
		  }
		},
	  });
	
	  // Close the modal
	  const modalElement = document.getElementById("passwordResetModal");
	  const passwordResetModalInstance = bootstrap.Modal.getInstance(modalElement);
	  passwordResetModalInstance.hide();
	});
	
	forgotPasswordLink.on('click', function (e) {
	  e.preventDefault();
	  promptPasswordReset();
	});
  
}

function markLibraryRendered() {
  libraryRendered = true;
}

function loginUser(email, password, termsAgreed = false) {
  removeAlert();
  showLoader();

  $.ajax({
	url: v1BaseUrl + 'auth/signIn',
	type: 'POST',
	xhrFields: {
	  withCredentials: true
	},
	data: {
	  email: email,
	  password: password,
	  termsAgreed: termsAgreed  // Pass the termsAgreed flag
	},
	success: function(response) {
	  if (response.success) {
		updateUserData().then(() => {
		  if (window.location.search.includes('?rt=cms')) {
			if (window.location.href.includes("dev-proxy.net")) {
			  window.location.href = "https://stagevault-admin.dev-proxy.net";
			} else {
			  window.location.href = "https://admin.stagevault.com";
			}
		  } else {
			renderLibrary();
			markLibraryRendered();
		  }
		}).catch((error) => {
		  showAlert(response.error);
		});
	  } else {
		hideLoader();
		
		// Check if the error is related to terms not being agreed
		if (response.error === "You must agree to the Privacy Policy and Terms of Use before logging in.") {
		  // Show the agreement modal
		  showAgreementModal(email, password);
		} else {
		  showAlert(response.error);
		}
	  }
	},
	error: function(xhr, textStatus, errorThrown) {
	  hideLoader();
	  const errorText = xhr.responseText;
	  console.error('Error:', errorText);
	  alert('Error: ' + errorText);
	},
  });
}

function showAgreementModal(email, password) {
  // Create and show the modal for agreement
  var agreementModal = $(`
	<div class="modal fade" id="termsAgreementModal" tabindex="-1" aria-labelledby="termsAgreementLabel" aria-hidden="true">
	  <div class="modal-dialog">
		<div class="modal-content">
		  <div class="modal-header">
			<h5 class="modal-title" id="termsAgreementLabel">Agree to Terms</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		  </div>
		  <div class="modal-body">
		    <div class="alert bg-secondary text-light p-2 ps-3">
			  <i class="bi bi-calendar-check me-2"></i> Effective September 8, 2024
			</div>
			<p>You must agree to the <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/legal/terms-of-use" target="_blank">Terms of Use</a> to continue.</p>
			<div class="form-check">
			  <input type="checkbox" class="form-check-input" id="termsAgreementCheckbox" required>
			  <label class="form-check-label" for="termsAgreementCheckbox">
				I agree to the Privacy Policy and Terms of Use.
			  </label>
			</div>
			<hr>
			<p>If you do not agree with these terms, please <a class="text-light" href="https://help.stagevault.com/open.php">open a support ticket</a> under the "Data Request" category to initate the deletion of your account.</p>
			<b class="mb-1">We will not delete your account without your consent.</b>
		  </div>
		  <div class="modal-footer">
			<button type="button" class="btn btn-primary" id="agreeButton" disabled>Agree and Continue</button>
		  </div>
		</div>
	  </div>
	</div>
  `);

  $('body').append(agreementModal);
  var modal = new bootstrap.Modal(document.getElementById('termsAgreementModal'), {
	keyboard: false
  });
  modal.show();

  // Enable the "Agree and Continue" button when checkbox is checked
  $('#termsAgreementCheckbox').on('change', function() {
	$('#agreeButton').prop('disabled', !this.checked);
  });

  // Handle agreement submission
  $('#agreeButton').on('click', function() {
	if ($('#termsAgreementCheckbox').is(':checked')) {
	  // Retry login with termsAgreed set to true
	  loginUser(email, password, true);

	  // Hide the modal
	  modal.hide();

	  // Once the modal is fully hidden, remove the modal and backdrop from the DOM
	  $('#termsAgreementModal').on('hidden.bs.modal', function () {
		modal.dispose();  // Properly dispose of the modal
		$('#termsAgreementModal').remove();  // Remove modal from DOM
		$('.modal-backdrop').remove();  // Remove any remaining backdrop
	  });
	}
  });
}

export function updateUserData() {
	return new Promise((resolve, reject) => {
	  $.ajax({
		url: v1BaseUrl + 'account/info',
		type: 'GET',
		xhrFields: {
		  withCredentials: true
		},
		success: function(response) {
		  if (response.success) {
			userData = response.data;
			resolve();
		  } else {
			reject(response.error);
		  }
		},
		error: function(xhr, textStatus, errorThrown) {
		  const errorText = xhr.responseText;
		  console.error('Error:', errorText);
		  reject(errorText);
		},
	  });
	});
  }
  
function signUpUser(firstName, lastName, email, password, legalAgreement) {
	removeAlert();
	showLoader();
	$.ajax({
	  url: v1BaseUrl + 'auth/signUp',
	  type: 'POST',
	  xhrFields: {
		withCredentials: true
	  },
	  data: {
		givenName: firstName,
		familyName: lastName,
		email: email,
		password: password,
	    termsAgreed: legalAgreement
	  },
	  success: function(response) {
		  updateUserData().then(() => {
			if (response.success) {
			  renderLibrary();
			} else {
				hideLoader();
			  showAlert(response.error);
			}
		  }).catch((error) => {
			showAlert(response.error);
			hideLoader();
		  });
	  },
	  error: function(xhr, textStatus, errorThrown) {
		  hideLoader();
		const errorText = xhr.responseText;
		console.error('Error:', errorText);
		showAlert('Error: ' + errorText);
	  },
	});
  }
  
  export function logoutUser() {
	  $.ajax({
		url: v1BaseUrl + 'auth/signOut', // Change this to the actual path of your PHP script
		type: 'GET',
		xhrFields: {
			withCredentials: true
		  },
		success: function(response) {
		  if (response.success) {
			  promptSignIn();
		  } else {
			  mkToast("account", "danger", "Error", response.error, 15000);
		  }
		},
		error: function(xhr, textStatus, errorThrown) {
		  // Handle the error, such as displaying an error message
		  const errorText = xhr.responseText;
		  console.error('Error:', errorText);
		  // Display the error message, for example, using an alert or showing it in a div
		  alert('Error: ' + errorText);
		},
	  });
	}

function createUserSettingsModal() {
	  return $(`
		<div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-header">
				<h5 class="modal-title" id="settingsModalLabel">Account</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			  </div>
			  <div class="modal-body">
				<form id="settingsForm">
				  <div class="mb-3">
					<label for="givenName" class="form-label">First Name</label>
					<input type="text" class="form-control" id="givenName" value="${userData.givenName}" required>
				  </div>
				  <div class="mb-3">
					<label for="familyName" class="form-label">Last Name</label>
					<input type="text" class="form-control" id="familyName" value="${userData.familyName}" required>
				  </div>
				  <div class="mb-3">
					<label for="email" class="form-label">Email</label>
					<input type="email" class="form-control" id="email" value="${userData.email}" required>
					<span class="mt-1 fw-bold text-warning" id="emailUnverifiedWarning">This email address is not verified. If you recently changed your email, you can still sign in using your last verified email.</span>
				  </div>
				</form>
				<a class="text-warning" id="resetPasswordBtn">Reset Password</a><br>
				<span class="text-secondary">
				  To request a copy of your account data or deletion, please <a class="text-light" target="blank" href="https://help.stagevault.com/open.php">submit a data request</a>.
				</span>
			  </div>
			  <div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				<button type="button" class="btn btn-primary" id="saveSettingsBtn">Save</button>
			  </div>
			</div>
		  </div>
		</div>
	  `);
	}

export function showSettings() {
	// Remove old
	if ($("#settingsModal").length == 1) {
		$("#settingsModal").remove();
	}
	
	const settingsModal = createUserSettingsModal();
	$("body").append(settingsModal);
	
	const modalElement = $("#settingsModal");
	const settingsModalInstance = new bootstrap.Modal(modalElement);
	settingsModalInstance.show();
	
	$("#resetPasswordBtn").off("click").on('click', function(event) {
		$.ajax({
			url: v1BaseUrl + "/auth/reset",
			method: "POST",
			xhrFields: {
			  withCredentials: true
			},
			data: {
			  email: userData.email,
			},
			success: function (response) {
			  if (response.success) {
				mkToast("account", "success", "Success", "Check your email inbox for a password reset link.", 15000);
			  } else {
				if (response.error !== null) {
					mkToast("account", "danger", "Error", "An error occured while trying to request a password reset email. (" + response.error + ")", 15000);
				} else {
					mkToast("account", "danger", "Error", "An unknown error occured while trying to request a password reset email.", 15000);
				}
			  }
			},
		  });
	});
	
	if (userData.emailVerified) {
		$("#emailUnverifiedWarning").addClass('d-none');
	} else {
		$("#emailUnverifiedWarning").removeClass('d-none');
	}
	
	
	$("#saveSettingsBtn").off("click").on('click', function(event) {
		saveUserSettings();
	});
	
	// Remove the modal from the DOM when it's closed
	  modalElement.on("hidden.bs.modal", function () {
		$(this).remove();
	  });
}

function saveUserSettings() {
  $.ajax({
	url: v1BaseUrl + "account/update",
	type: "POST",
	xhrFields: {
	  withCredentials: true
	},
	data: {
	  givenName: $("#givenName").val(),
	  familyName: $("#familyName").val(),
	  email: $("#email").val(),
	},
	success: function (response) {
	  if (response.success) {
		updateUserData().then(() => {
		  // showAlert("Settings saved successfully.", "success");
		  hideSettings();
		  renderLibrary("success", "Your account settings have been saved.");
		}).catch((error) => {
		  alert("Error: " + error, "danger");
		});
	  } else {
		alert("Error: " + response.error, "danger");
	  }
	},
	error: function (xhr, textStatus, errorThrown) {
	  const errorText = xhr.responseText;
	  console.error("Error:", errorText);
	  alert("Error: " + errorText, "danger");
	},
  });
}

function hideSettings() {
  const modalElement = document.getElementById("settingsModal");
  const settingsModalInstance = bootstrap.Modal.getInstance(modalElement);
  settingsModalInstance.hide();
}

export function resendEmailConfirmation() {
	$("#resendEmailConfirmationBtn").addClass("disabled");
	$.ajax({
		url: v1BaseUrl + "auth/confirm",
		type: "GET",
		xhrFields: {
		  withCredentials: true
		},
		success: function (response) {
		  if (response.success) {
			mkToast("account", "success", "Success", "We've successfully sent you another email confirmation link. Please follow the link we've sent to confirm your account.", 30000);
		  } else {
			mkToast("account", "danger", "Error", "An error occured when we tried sending you a new verification email. Please try again in a few moments. If the issue persists, contact support@stagevault.com", 30000);
		  }
		},
		error: function (xhr, textStatus, errorThrown) {
		  const errorText = xhr.responseText;
		  console.error("Error:", errorText);
		  alert("Error: " + errorText, "danger");
		},
	  });
}